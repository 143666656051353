import React, {lazy} from "react";
import {Routes, Route, Navigate} from "react-router-dom"
import {YMInitializer} from "react-yandex-metrika";
import {Layout, Spin} from "antd";
import NotFoundPage from "./components/NotFoundPage";

import "./App.css";

const HitbCtfSingapore2022 = lazy(() => import("./events/hitb-ctf-singapore-2022"));
const HitbCtfPhuket2023 = lazy(() => import("./events/hitb-ctf-phuket-2023"));


function App() {
    const spin = <Layout style={{height: "100vh", justifyContent: "center"}}><Spin size="large" /></Layout>;
    return <React.Suspense fallback={spin}>
        <Routes>
            <Route index element={<Navigate replace to="/hitb-ctf-phuket-2023"/>} />
            <Route path="/hitb-ctf-singapore-2022/*" element={<HitbCtfSingapore2022 eventSlug="hitb-ctf-singapore-2022" isRegistrationOpen={false}/>} />
            <Route path="/hitb-ctf-phuket-2023/*" element={<HitbCtfPhuket2023 eventSlug="hitb-ctf-phuket-2023" isRegistrationOpen={false}/>} />
            <Route path="*" element={<NotFoundPage/>}/>
        </Routes>
        <YMInitializer accounts={[90033626]} options={{clickmap:true, trackLinks:true, accurateTrackBounce:true, webvisor:true}} version="2"/>
    </React.Suspense>;
}

export default App;
