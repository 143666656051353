import "./NotFoundPage.css"
import {Button} from "antd";

function NotFoundPage() {
    // Based on https://colorlib.com/wp/template/colorlib-error-404-3/, licensed by CC BY 3.0
    return <div className="notfound-container">
        <div className="notfound">
            <div className="notfound-404">
                <h3>Oops! Page not found</h3>
                <h1><span>4</span><span>0</span><span>4</span></h1>
            </div>
            <h2>We are sorry, but the page you requested was not found</h2>
            <Button href="/" type="primary" size={"large"}>Go to the main page</Button>
        </div>
    </div>
}

export default NotFoundPage;
